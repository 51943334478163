<template>
  <section class="orders">
    <div
      class="table-wrap"
      v-if="$router.user.roleScore <= 1 || $router.user.roleScore >= 2"
    >
      <div class="row">
        <div
          class="col-12"
          :class="$router.user.roleScore > 1 ? 'col-md-7 col-lg-8' : ''"
        >
          <div class="row no-gutters justify-content-between">
            <div class="col-auto"><h2>Production</h2></div>
            <div class="col-auto ml-auto row align-items-top filters">
              <div class="col-12 col-md-auto">
                <label>Keyword:</label>
                <input
                  v-model="filters.keyword"
                  @keyup.enter="getFilteredProductionOrders()"
                  type="text"
                  placeholder="Type keyword"
                  class="form-control form-control-sm"
                />
                <span class="small">(order number, product SKU, serial)</span>
                <br>
                <label>Typology:</label>
                <select class="form-control form-control-sm" v-model="filters.typology">
                  <option v-for="t in typologies" :key="t.code" :value="t.code">{{ t.name }}</option>
                </select>
              </div>
              <div class="col-12 col-md-auto">
                <label>Dates:</label>
                <input
                  type="date"
                  v-model="filters.date_start"
                  class="form-control form-control-sm"
                  placeholder="From:"
                />
                <input
                  type="date"
                  v-model="filters.date_end"
                  class="form-control form-control-sm"
                  placeholder="To:"
                />
              </div>
              <div class="col-12 col-md-auto">
                <label>Hide Status:</label>
                <div class="form-check">
                  <div>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="hide_new"
                      value="new"
                      v-model="filters.hide_statuses"
                    />
                    <label class="form-check-label" for="hide_new">New</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="hide_in-progress"
                      value="in-progress"
                      v-model="filters.hide_statuses"
                    />
                    <label class="form-check-label" for="hide_in-progress"
                      >In Progress</label
                    >
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="hide_completed"
                      value="completed"
                      v-model="filters.hide_statuses"
                    />
                    <label class="form-check-label" for="hide_completed"
                      >Completed</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-auto">
                <div class="btn-group-vertical actions">
                  <button
                    class="btn btn-primary btn-lg btn-block"
                    @click="getFilteredProductionOrders()"
                    title="Search"
                  >
                    <i class="fa fa-search"></i>Search
                  </button>
                  <button
                    class="btn btn-danger btn-sm btn-block"
                    @click="resetFilters()"
                    title="Reset"
                  >
                    <i class="fa fa-times"></i>Reset
                  </button>
                  <download-excel
                    class="btn btn-block btn-info btn-sm"
                    :data="excelData"
                    name="GW_ProductionOrdersDetailed.xls"
                    ><i class="fa fa-file-excel"></i>Export</download-excel
                  >
                  <download-excel
                    class="btn btn-block btn-info btn-sm"
                    :data="excelData2"
                    name="GW_ProductionOrders.xls"
                    ><i class="fa fa-file-excel"></i>Export 2</download-excel
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>Actions</th>
                  <th># Order</th>
                  <th>Date</th>
                  <th>Products</th>
                  <th>Status</th>
                  <th>Notes</th>
                  <th v-if="$router.user.roleScore > 1">SAP</th>
                  <th>Print</th>
                </tr>
              </thead>
              <tbody v-if="!loadingData && orders_count > 0">
                <template v-for="order in orders">
                  <SingleProductionOrder v-if="$router.user.roleScore > 1 || $router.user.roleScore < 1" :key="order.id_number" :order="order"></SingleProductionOrder>
                </template>
              </tbody>
              <span v-else>
                <div class="loading-data" v-if="loadingData">
                  <span class="loading"></span>
                  <h5>Loading orders ...</h5>
                </div>
                <div class="loading-data" v-else>
                  <h6>No orders found.</h6>
                </div>
                <div class="clearer"></div>
              </span>
            </table>
          </div>
          <nav aria-label="Orders navigation" v-if="orders_count > 0">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="page == 1 ? 'disabled' : ''">
                <a
                  @click.prevent="getProductionOrders((page -= 1))"
                  class="page-link"
                  href="#"
                  aria-label="Previous"
                  ><span aria-hidden="true">&laquo;</span
                  ><span class="sr-only">Previous</span></a
                >
              </li>
              <li class="page-item" :class="page == 1 ? 'disabled' : ''">
                <a
                  @click.prevent="getProductionOrders(1)"
                  class="page-link"
                  href="#"
                  >First</a
                >
              </li>
              <li
                v-for="page_number in pages"
                :key="page_number"
                class="page-item"
                :class="page_number == page ? 'active' : ''"
              >
                <a
                  v-if="!(page_number <= page - 10 || page_number >= page + 10)"
                  class="page-link"
                  href="#"
                  @click.prevent="getProductionOrders(page_number)"
                >
                  {{ page_number }}</a
                >
              </li>
              <li class="page-item" :class="page == pages ? 'disabled' : ''">
                <a
                  @click.prevent="getProductionOrders(pages)"
                  class="page-link"
                  href="#"
                  >Last</a
                >
              </li>
              <li class="page-item" :class="page == pages ? 'disabled' : ''">
                <a
                  @click.prevent="getProductionOrders((page += 1))"
                  class="page-link"
                  href="#"
                  aria-label="Next"
                  ><span aria-hidden="true">&raquo;</span
                  ><span class="sr-only">Next</span></a
                >
              </li>
            </ul>
          </nav>
        </div>
        <div
          v-if="$router.user.roleScore > 1 && !loadingData"
          class="col-12 col-md-5 col-lg-4"
        >
          <div class="card mb-4">
            <div class="m-4">
              <!-- <router-link to="/inbound-orders" class="btn btn-md btn-warning btn-block">Ordini in Entrata</router-link> -->
              <router-link v-if="$router.user.roleScore >=2" to="/production-nc" class="btn btn-md btn-outline-warning btn-block">Non Conformità</router-link>
            </div>
          </div>
          <NewProductionOrder
            :lastOrder="lastOrderId"
          ></NewProductionOrder>
            <div class="card mt-4">
              <div class="m-4">
                <h5 class="mb-4">Upload Microline Tests</h5>
                <a href="/production-test" target="_blank" class="btn btn-md btn-outline-info btn-block">Upload JSON files</a>
              </div>
            </div>
          <DismissProduct/>
        </div>
      </div>
    </div>
    <div v-else>Access denied! You can't access this page.</div>
  </section>
</template>

<script>
import ProductionService from "@/services/ProductionService";
import SingleProductionOrder from "./SingleProductionOrder";
import DismissProduct from "./DismissProduct";
import NewProductionOrder from "./NewProductionOrder";
import downloadExcel from "../utils/downloadExcel";
import data from "@/assets/data.json";
export default {
  name: "ProductionOrders",
  data() {
    return {
      page: 1,
      pages: 0,
      orders: "",
      order_list: "",
      orders_count: "",
      loadingData: false,
      typologies: data.production_typologies,
      filters: {
        keyword: "",
        date_start: null,
        date_end: null,
        hide_statuses: [],
        typology: null
      },
      excelData: [],
      excelData2: []
    };
  },
  components: {
    SingleProductionOrder,
    NewProductionOrder,
    DismissProduct,
    downloadExcel,
  },
  mounted() {
    this.page = 1;
    if(this.$router.user.roleScore < 1) {
      this.filters.hide_statuses = ['completed']
      this.getFilteredProductionOrders()
    } else {
      this.getProductionOrders(this.page);
    }
  },
  computed: {
    lastOrderId() {
      const list = [...this.orders]
      return list ? list.sort((a, b) => b.id_number.localeCompare(a.id_number)).filter(o => o.typology === 'production' || o.typology === 'rework')[0]?.id_number : ''
    }
  },
  methods: {
    async getProductionOrders(page) {
      this.loadingData = !this.loadingData;
      page <= 1
        ? (this.page = 1)
        : page > this.pages
        ? (this.page = this.pages)
        : (this.page = page);
      const response = await ProductionService.getProductionOrders(page - 1);
      this.order_list = response.data.models;
      this.orders = this.order_list;
      this.orders_count = this.order_list ? this.order_list.length : 0;
      this.pages = response.data.pages;
      this.loadingData = !this.loadingData;
      this.setExcelData(this.orders);
      this.setExcelData2(this.orders);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getFilteredProductionOrders() {
      this.loadingData = !this.loadingData;
      const response = await ProductionService.getFilteredProductionOrders(
        this.filters
      );
      this.order_list = response.data;
      this.orders = this.order_list;
      this.orders_count = this.order_list.length;
      this.pages = 1;
      this.loadingData = !this.loadingData;
      this.setExcelData(this.orders);
      this.setExcelData2(this.orders);
    },
    resetFilters() {
      this.filters = {
        keyword: "",
        date_start: null,
        date_end: null,
        hide_statuses: [],
        typology: null,
      };
      this.getProductionOrders();
    },
    setExcelData(orders) {
      this.excelData = [];
      let data = [];
      orders.map((order) => {
        const start_production_date = order.start_production_date ? new Date(order.start_production_date).toISOString() : order.serials && order.serials.length && order.serials[0].timestamp ? new Date(order.serials[0].timestamp).toISOString() : ''
        const end_production_date = order.end_production_date ? new Date(order.end_production_date).toISOString() : order.serials && order.serials.length && order.serials[order.serials.length - 1].timestamp ? new Date(order.serials[order.serials.length - 1].timestamp).toISOString() : ''
        order.serials.map(serial => {
          let obj = {
            id_number: order.id_number,
            status: order.status,
            sku: order.product.sku,
            commercial_code: order.product.commercial_code,
            commercial_name: order.product.commercial_name,
            name: order.product.name,
            family: order.product.family,
            quantity: order.product.quantity,
            date: order.date.substring(0, 10),
            start_production_date: start_production_date,
            end_production_date: end_production_date,
            timestamp: serial.timestamp,
            product_serials: serial.product,
            mac_serials: serial.mac
          }
          data.push(obj);
        })
      });
      this.excelData = data;
    },
    setExcelData2(orders) {
      this.excelData2 = [];
      let data = [];
      orders.map((order) => {
        data.push({
          'SKU Fatturazione TGT': '',
          'N. doc. SAP': order.sap_document_id,
          'N° Ordine GW': order.id_number,
          'Cod. prodotto': order.product.sku,
          'Descrizione prodotto': order.product.name,
          'Quantità pianificata': order.product.quantity,
          'Quantità Completata': order.product.quantity_actual,
          'Quantità Rilavorata (NC)': order.product.quantity_actual ? order.product.quantity - order.product.quantity_actual : '',
          'Stato': order.status,
          'Priorità (Data)': new Date(order.date).toISOString(),
          'Conferma Data TG': '',
          'Materiale': '',
          'Note': order.notes
        });
      });
      this.excelData2 = data;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";

table {
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.1);

  thead {
    color: #fff;

    tr {
      background-color: $gray02 !important;
      th {
        border: 1px solid !important;
        border-color: $gray03 !important;
        border-color: rgba($gray02, 0.4);
        white-space: nowrap;
        font-weight: 300;
        padding: 0.75rem;
        position: relative;

        .btn {
          position: absolute;
          right: 1rem;
          top: 0.4rem;
          background-color: $gray03;
          color: #fff;
        }
      }
    }
  }

  td,
  th {
    vertical-align: middle !important;

    span {
      display: block;
    }
  }
}

tbody {
  tr {
    background-color: #fff;

    &:nth-of-type(odd) {
      background-color: #f9f9f9 !important;
    }

    &:hover {
      background-color: #f4f4f4 !important;
    }
  }
}

.filters {
  margin: 0;
  padding: 1rem !important;
  color: #fff;
  background-color: $gray02;
  border-bottom: 0;
  max-width: 100%;

  label {
    font-weight: 700;
  }

  input,
  button,
  select,
  option,
  label {
    font-size: 0.8rem;

    @include mobile {
      margin-top: 0.5rem;
    }
  }

  .form-check {
    max-width: 200px;

    &-label {
      font-weight: 400;
    }
  }

  .col-auto {
    border-right: 1px solid rgba($gray01, 0.4);

    &:last-of-type {
      border-right: none;
    }
  }
}

.loading-data {
  position: absolute;
  padding: 2rem;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  display: block;

  h5 {
    margin-top: 2.5rem;
  }
}

.clearer {
  display: block;
  padding: 4rem;
}

.pagination {
  flex-wrap: wrap;
}
</style>
