<template lang="pug">
.card
  .card-body
    .row(v-if="$router.user.roleScore >= 2")
      .col-md-6
      .col-md-6
        .form-check
          input.mr-2(id="simulate" type="checkbox" v-model="simulateError") 
          label.text-danger Simulate Checkup Error
        .form-check
          input.mr-2(id="downgrade" type="checkbox" v-model="downgradeFirmware") 
          label.text-danger Downgrade Firmware
    .row
      .col-12.col-md-6
        .card.card--device(:style="`border-left: 6px solid ${color};`")
          .card-body
            .d-flex.align-items-center.justify-content-between
              h6.mb-0.card-title Dispositivo
              button.btn.btn-sm.btn-primary(v-if="isSupported" @click="startTestPipeline" :disabled="isTesting") Inizia Collaudo
              .text.text-danger(v-else) Bluetooth is not supported on this device/browser.
            table.table.mt-2(v-if="device || isTesting")
              tr
                td Nome 
                td {{ deviceInfo.name }}
              tr
                td Firmware 
                td 
                  span {{ deviceInfo.firmware.version }} 
                  span(v-if="deviceInfo.firmware.latest") (Ultima: {{ deviceInfo.firmware.latest }})
              tr
                td  Check Up
                td 
                  span.badge.mx-1(
                    v-for="(value, key) in deviceInfo.checkup" 
                    :class="value ? 'badge-success' : 'badge-danger'"
                    :title="legend[key]"
                  ) {{ key }}
              tr(v-if="isShelfy")
                td Batteria
                td 
                  span.badge.mx-1(v-if="deviceInfo.battery.percentage" :class="deviceInfo.battery.percentage < 30 || deviceInfo.battery.percentage > 50  ? 'badge-warning' : 'badge-success'") {{ deviceInfo.battery.percentage }}%
                  span.mx-1 -
                  span(v-if="deviceInfo.battery.voltage") {{ deviceInfo.battery.voltage }} mV
              //- tr
              //-   td Comandi Led
              //-   td 
              //-     span.badge.mx-1(:class="deviceInfo.leds.output === null ? 'badge-secondary' : deviceInfo.leds.output ? 'badge-success' : 'badge-danger'") OUT
              //-     span.badge.mx-1(:class="deviceInfo.leds.internal === null ? 'badge-secondary' : deviceInfo.leds.internal ? 'badge-success' : 'badge-danger'") IN
              //- tr
              //-   td Comando Ventola
              //-   td 
              //-     span.badge.mx-1(:class="deviceInfo.fan === null ? 'badge-secondary' : deviceInfo.fan ? 'badge-success' : 'badge-danger'") {{ deviceInfo.fan === null ? '' : deviceInfo.fan ? 'OK' : 'NOT OK' }}
              tr(v-if="isShelfy")
                td Shipping Mode 
                td  
                  span.badge(:class="deviceInfo.shipping_mode === null ? 'badge-secondary' : deviceInfo.shipping_mode ? 'badge-success' : 'badge-secondary'") {{ deviceInfo.shipping_mode === null ? '' : deviceInfo.shipping_mode ? 'Activated' : 'Not Activated' }}
            .alert.alert-danger(v-if="errorMessage") {{ errorMessage }}
            button.btn.btn-sm.btn-danger(v-if="isTesting" @click="resetTest" :disabled="!isTesting") 
              i.fa.fa-stop-circle.mr-2
              span Arresta Collaudo
      .col-12.col-md-6
        .steps 
          template(v-for="(step, index) in steps")
            .step(:class="step.status" v-if="!device || step.products.includes(deviceInfo.name.substr(0,3))")
              .d-flex.align-items-center
                .step__status
                  template(v-if="step.status === 'to-do'") {{ index + 1 }}
                  i.fa.fa-spinner.spinner(v-if="step.status === 'in-progress'")
                  i.fa.fa-check(v-if="step.status === 'passed'")
                  i.fa.fa-times(v-if="step.status === 'failed'")
                  i.fa.fa-forward(v-if="step.status === 'skipped'")
                div
                  .step__name {{ step.name }}
                  small.step__description {{ step.description }}
              .step_substeps(v-if="step.substeps")
                .step.substep.d-flex(v-for="(substep, i) in step.substeps" :class="substep.status" v-if="substep.status !== 'to-do'")
                  .step__status
                    template(v-if="substep.status === 'to-do'") {{ index + 1 }}.{{ i + 1 }}
                    i.fa.fa-spinner.spinner(v-if="substep.status === 'in-progress'")
                    i.fa.fa-check(v-if="substep.status === 'passed'")
                    i.fa.fa-times(v-if="substep.status === 'failed'")
                    i.fa.fa-forward(v-if="substep.status === 'skipped'")
                  .step__name {{ substep.name }}
        .mt-1(v-if="isTestCompleted || isTestFailed")
          template(v-if="this.deviceInfo.checkup.result")
            button.btn-block.btn.btn-success.mb-1(:disabled="isSaving" @click="endTest(false)") Tutto ok
            ReportNC(v-if="order" :order="order" 
              :mac="deviceInfo.mac" :disabled="isSaving" 
              @ncReported="endTest"
            ) (Manual)
          ReportNC(v-else="order" :order="order" 
            :mac="deviceInfo.mac" :disabled="isSaving" 
            :autoReasonIds="failedTestReasonId"
            @ncReported="endTest"
          ) (Auto)
</template>
<script>

import {BleService, DeviceType, StartupMode, WifiConnectionMode, WifiSecurityMethod} from "@vitesy/vhub-browser-ble";

const CONFIG = {
  serviceUUID: 'c954c6e6-3016-4416-a72f-b184a4f039e0',
  characteristicUUID: '2b3247fd-28db-413c-934f-aa4c271984c9',
  WiFiCredentials: {
    SSID: 'MW40V_DA06',
    Password: '98541014',
    SecurityMethod: WifiSecurityMethod.WPA2_PSK
  },
  latestFirmwareVersion: '0.0.09',
  firmwaresURL: 'https://static.vitesyhub.co/firmwares/'
}

const COLORS = {
  red: [0xFF, 0x00, 0x00],
  green: [0x00, 0xFF, 0x00],
  blue: [0x00, 0x00, 0xFF],
  purple: [0xFF, 0x00, 0xFF],
  yellow: [0xFF, 0xFF, 0x00],
  orange: [0xFF, 0xA5, 0x00],
  cyan: [0x00, 0xFF, 0xFF]
}

const STATUS = {
  TO_DO: "to-do",
  IN_PROGRESS: "in-progress",
  PASSED: "passed",
  FAILED: "failed",
  SKIPPED: "skipped",
}

import ProductionService from "@/services/ProductionService";
import ReportNC from "./ReportNC";

export default {
  name: 'SingleDeviceTesting2',
  props: ['color'],
  data() {
    return {
      order: null,
      simulateError: false, // TEMP
      downgradeFirmware: false, // TEMP
      isSupported: false,
      isConnected: false,
      attempt: 0,
      isTesting: false,
      isTestCompleted: false,
      isTestFailed: false,
      isSaving: false,
      device: null,
      server: null,
      characteristic: null,
      deviceInfo: {},
      failedTestReasonId: null,
      errorMessage: null,
      gattOperationInProgress: false,
      steps: [
        {
          name: 'Connessione al Dispositivo',
          status: STATUS.TO_DO,
          products: ['SLF','SMG']
        },
        {
          name: 'Controllo Versione Firmware',
          status: STATUS.TO_DO,
          products: ['SLF','SMG'],
          substeps: [
            {
              name: 'Connessione al Wi-Fi',
              status: STATUS.TO_DO,
            },
            {
              name: 'Aggiornamento Firmware',
              status: STATUS.TO_DO,
            },
          ]
        },
        {
          name: 'Check Up Automatico',
          status: STATUS.TO_DO,
          products: ['SLF','SMG']
        },
        {
          name: 'Controllo Manuale',
          description: 'Percentuale carica batteria, led e ventola accesi, difetti estetici.',
          status: STATUS.TO_DO,
          products: ['SLF','SMG']
        },
        {
          name: 'Collaudo Completato',
          status: STATUS.TO_DO,
          products: ['SLF','SMG']
        }
      ],
      legend: {
        CHG: "Battery Charger",
        SHT4x: "Sensore Temperatura/Umidità",
        LIS2DH12: "Accelerometro",
        LPS22HH: "Sensore Pressione/Temperatura",
        BME680: "Sensore Gas",
        ESP32: "Modulo Wi-Fi/BLE",
        BLE: "Bluetooth",
        VoC: "Sensore VoC",
        HUM: "Sensore Umidità"
      }
    };
  },
  computed: {
    isShelfy(){
      return this.deviceInfo?.name?.startsWith('SLF')
    },
    isSmeg(){
      return this.deviceInfo?.name?.startsWith('SMGHM')
    },
  },
  components: { ReportNC },
  methods: {
    wait(ms) {
      return new Promise(resolve => setTimeout(resolve, ms) )
    },
    async resetTest(){
      this.device ? this.device.removeEventListener('gattserverdisconnected', async () => { await this.handleDeviceDisconnection() }) : ''
      if (this.server?.connected) {
        await this.characteristic?.stopNotifications()
        await this.server.disconnect()
      }

      this.device = null
      this.server = null
      this.characteristic = null
      this.isTesting = false
      this.isTestCompleted = false
      this.isTestFailed = false
      this.errorMessage = null

      this.steps.forEach(step => {
        step.status = "to-do"
        if (step.substeps) {
          step.substeps.forEach(substep => {
            substep.status = "to-do"
          })
        }
      })

      this.deviceInfo = {
        name: '',
        mac: '',
        firmware: {
          version: '-',
          latest: null,
          updated: false
        },
        battery: {
          percentage: '',
          voltage: ''
        },
        leds: {
          output: null,
          internal: null
        },
        fan: null,
        checkup: {},
        shipping_mode: null,
        start_timestamp: null
      }
    },
    async connectToDevice() {
      this.steps[0].status = STATUS.IN_PROGRESS
      try {
        const device = await BleService.requestDevice(DeviceType.SMGHM)

        this.deviceInfo.name = device.name
        this.deviceInfo.mac = device.id.replace(/:/g, '')

        await device.connect()
        this.steps[0].status = STATUS.PASSED

        return device
      } catch (e) {
        console.error(e)
        this.steps[0].status = STATUS.FAILED
      }
    },
    compareVersions(a, b) {
        const a_ = a.split('.').map(x => +x)
        const b_ = b.split('.').map(x => +x)

        a_.push(0, 0, 0)
        b_.push(0, 0, 0)

        for (let i = 0; i < 3; i++) {
          if (a_[i] < b_[i]) {
            return 1
          } else if (a_[i] > b_[i]) {
            return -1
          }
        }
        return 0
    },
    async checkFirmwareVersion(device){
      this.steps[1].status = STATUS.IN_PROGRESS
      try {
        const { firmwareVersion } = await device.getFirmwareVersion()
        this.deviceInfo.firmware.version = firmwareVersion

        const shouldUpdate = this.compareVersions(firmwareVersion, CONFIG.latestFirmwareVersion) > 0
        if (shouldUpdate) {
          await this.updateFirmware(device, firmwareVersion, CONFIG.latestFirmwareVersion)
        }
        this.steps[1].status = STATUS.PASSED
      } catch (e) {
        console.error(e)
        this.steps[1].status = STATUS.FAILED
      }
    },
    async updateFirmware (device, currentFirmwareVersion, latestFirmwareVersion) {
      this.steps[1].substeps[0].status = STATUS.IN_PROGRESS

      // TODO: check if '0.0.06' is correct
      const supportsOTACommand = this.compareVersions(currentFirmwareVersion, '0.0.06') <= 0
      if (supportsOTACommand) {
        try {
          await this.connectToWifi(device, CONFIG.WiFiCredentials.SSID, CONFIG.WiFiCredentials.Password, CONFIG.WiFiCredentials.SecurityMethod)
          this.steps[1].substeps[0].status = STATUS.PASSED
        } catch (e) {
          console.error(e)
          this.steps[1].status = STATUS.FAILED
          this.steps[1].substeps[0].status = STATUS.FAILED
          this.failedTestReasonId = [2.1]

          this.sendError(`Unable to connect to Wi-Fi!`)
        }

        this.steps[1].substeps[1].status = STATUS.IN_PROGRESS

        try {
          await device.setOtaWifi(latestFirmwareVersion)
          this.steps[1].substeps[1].status = STATUS.PASSED
        } catch (e) {
          console.error(e)
          this.steps[1].status = STATUS.FAILED
          this.steps[1].substeps[1].status = STATUS.FAILED
          this.failedTestReasonId = [2.2]

          this.sendError(`Unable to Enroll Device!`)
        }
      } else {
        this.steps[1].substeps[0].status = STATUS.SKIPPED
        this.steps[1].substeps[1].status = STATUS.IN_PROGRESS
        try {
          const response = await fetch(CONFIG.firmwaresURL + 'smghm/v' + latestFirmwareVersion + '.bin')
          const firmware = await response.arrayBuffer()
          console.log(firmware)

          await device.updateFirmware(firmware)

          await device.connect()

          await device.setRestart(StartupMode.APPLICATION)

          await this.wait(3000)

          await device.connect()

          await this.checkFirmwareVersion(device)

          this.steps[1].substeps[1].status = STATUS.PASSED
        } catch (e) {
          console.error(e)
          this.steps[1].status = STATUS.FAILED
          this.steps[1].substeps[1].status = STATUS.FAILED
          this.failedTestReasonId = [2.2]

          this.sendError(`Unable to Update!`)
        }
        this.steps[1].substeps[1].status = STATUS.IN_PROGRESS
      }

      this.steps[1].substeps[1].status = STATUS.PASSED

      const { firmwareVersion } = await device.getFirmwareVersion()
      console.log({firmwareVersion})

      this.deviceInfo.firmware.updated = true
      this.steps[1].substeps = STATUS.PASSED


    },
    async connectToWifi(device, ssid, password, security) {
      await device.setReset()
      await device.setWifiSsid(ssid)
      await device.setWifiPsw(password)
      await device.setWifiConnect(WifiConnectionMode.AUTO, security)
    },
    async checkup(device){
      this.steps[2].status = STATUS.IN_PROGRESS

      try {
        const { esp32, voc, hum, ble } = await device.getCheckUp()
        console.log({ esp32, voc, hum, ble})

        const result = esp32 && voc && hum && ble
        this.deviceInfo.checkup.result = result

        if (!result) {
          throw new Error()
        }

        this.steps[2].status = STATUS.PASSED
      } catch (e) {
        this.steps[2].status = STATUS.FAILED
        this.failedTestReasonId = [4.0]
        this.sendError('Checkup failed!')
      }
    },
    sendError(error){
      throw new Error(error)
    },
    async startTestPipeline() {
      await this.resetTest()
      this.isTesting = true
      try {
        const device = await this.connectToDevice()
        console.log({device})

        const response = await ProductionService.getProductionOrderByMac(device.id)
        if (response && response.data) {
          this.order = response.data
        } else {
          alert('Dispositivo non trovato in nessun ordine di produzione.')
          this.order = null
        }

        this.deviceInfo.start_timestamp = new Date().getTime()

        await this.checkFirmwareVersion(device)
        await this.checkup(device)

        // await this.checkComponents()
        this.isTestCompleted = true
      } catch (error) {
        this.errorMessage = error
        console.error('PIPELINE ERROR:', error)
        // await this.writeOutputLeds([...COLORS.red,...COLORS.red,...COLORS.red,...COLORS.red])
        this.isTestFailed = true
        this.isTestCompleted = true
      }
    },
    async endTest(reportedNC) {

      if (reportedNC) {
        // await this.writeOutputLeds([...COLORS.red,...COLORS.red,...COLORS.red,...COLORS.red])
        if (reportedNC.error) {
        alert(`Errore durante segnalazione della non conformità: ${reportedNC.error}`)
        }
      }

      this.isSaving = true

      // await this.writeInternalLeds(false)
      // await this.writeFan(false)

      this.steps[3].status = this.isTestFailed ? STATUS.SKIPPED : !reportedNC ? STATUS.PASSED : STATUS.FAILED

      await this.wait(1000)

      this.steps[4].status = STATUS.IN_PROGRESS
      /* if (this.server?.connected) {
        await this.characteristic.stopNotifications()
        await this.server.disconnect()
      } else {
        this.device?.removeEventListener('gattserverdisconnected', async () => { await this.handleDeviceDisconnection() })
      } */
      await this.saveTestResponse(reportedNC)
      this.steps[4].status = STATUS.PASSED

      await this.wait(1000)
      this.isSaving = false
      await this.resetTest()
    },
    async saveTestResponse(reportedNC){
      // this.steps[6].status = STATUS.IN_PROGRESS

      const testLog = {
        id: this.deviceInfo.name,
        type: 'device',
        data: {
          id_number: this.order ? this.order.id_number : null,
          ...this.deviceInfo,
          end_timestamp: new Date().getTime(),
          nc: reportedNC
        }
      }
      const testResponse = await ProductionService.addProductionTest(testLog)
      console.log(testResponse, testLog)

      const response = true
      // this.steps[6].status = response ? STATUS.PASSED : STATUS.FAILED
    }

  },
  async mounted() {
    if ('bluetooth' in navigator) {
      this.isSupported = true
      window.addEventListener("keyup", (e) => {
        e.keyCode === 32 ? !this.isTesting ? this.startTestPipeline() : '' : ''
      })

      // const { data } = await ProductionService.getProductionOrder(this.id_number)
      // this.order = data

    } else {
      console.error('Bluetooth is not supported on this device/browser.');
      return;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
.code {
  background-color: #2c2c2c;
  color: #B4D455;
}

.steps {
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    left: 1.25rem;
    top: 0;
    background-color: $lightblue;
  }
}
.step, .substep {
  position: relative;
  background-color: #fff;
  border: 1px solid $lightblue;
  border-radius: 2rem;
  padding: .25rem .25rem;
  margin: .5rem 0;
  align-items: center;

  &__description {
    line-height: 0;
  }

  &__status {
    background-color: $lightblue;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    text-align: center;
    font-size: 1rem;
    line-height: 2rem;
    margin-right: .5rem;
    flex: 0 0 auto;
  }
  &.passed {
    .step__status {
      background-color: $green!important;
      animation: none!important;
      color: #fff;
    }
    border-color: $green;
  }
  &.failed {
    .step__status {
      background-color: $red;
      color: #fff;
    }
    border-color: $red;
  }
  &.skipped {
    .step__status {
      background-color: $gray01;
      color: $gray02;
    }
    border-color: $gray02;
  }
  &.to-do {
    opacity: .75;
  }
  &.in-progress {
    .step__status {
      @extend .rotate;
      background-color: $blue;
      color: #fff;
    }
    border-color: $blue;
  }
}
</style>
