<template>
  <div>
    <form class="form" @submit.prevent>
      <a class="back btn btn-danger btn-lg" href="/orders"
        ><i class="fa fa-times"></i
      ></a>
      <div class="form-row">
        <div class="col-12 mb-2" v-if="primaryOrder">
          <h5 class="mb-4 text-muted text-center">
            Clone of Order <kbd>#{{ primaryOrder.id_number }}</kbd>
          </h5>
          <hr />
        </div>
        <div class="col-12 mb-2" v-if="editOrderId">
          <h5 class="mb-4 text-muted text-center">
            Editing Order <kbd>#{{ editOrderId}}</kbd>
          </h5>
          <hr />
        </div>
        <div class="col-12 col-md-6">
          <h5>Order Details</h5>
          <label class="salesforce" for="quoteId">Salesforce Quote ID</label>
          <div class="d-inline-flex w-100 mb-4">
            <input name="quoteId" v-model="salesforceQuoteId" class="form-control mr-2 mb-0" type="text" placeholder="Insert Salesforce Quote ID" @keyup.enter="salesforceGetQuote()" />
            <button class="btn btn-sm btn-primary" @click.prevent="salesforceGetQuote()">Import Quote</button>
          </div>
          <label for="id_number">Order Number</label>
          <div class="d-inline-flex w-100 mb-4 align-items-center">
            <input
              class="form-control mb-0"
              type="text"
              name="id_number"
              placeholder="Order Number"
              v-model="formData.id_number"
              required
            />
            <button class="btn btn-sm" @click.prevent="generateIdNumber()">
              <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.72603 17.6004H11.5258V15.4005H2.72603V6.60017H11.5258V4.40027H2.72603C1.51114 4.40027 0.526123 5.38533 0.526123 6.60017V15.4C0.525755 16.6152 1.51081 17.6003 2.72603 17.6003V17.6004Z" fill="black"/>
              <path d="M20.3257 4.40016H15.9259V2.20026H13.726V19.8008H15.9259V17.6005H20.326C21.5409 17.6005 22.526 16.6155 22.526 15.4006V6.60035C22.526 5.38546 21.5409 4.40045 20.3257 4.40045V4.40016ZM20.3257 15.4006H15.9259V6.60035H20.326V15.4006H20.3257Z" fill="black"/>
              <path d="M15.9263 0H20.3265V2.19991H15.9263V0Z" fill="black"/>
              <path d="M9.32617 0H13.7264V2.19991H9.32617V0Z" fill="black"/>
              <path d="M9.32617 19.8001H13.7264V22H9.32617V19.8001Z" fill="black"/>
              <path d="M15.9263 19.8001H20.3265V22H15.9263V19.8001Z" fill="black"/>
              </svg>
            </button>
          </div>
          <label for="original_order_id_number">Original Order Number</label>
          <br>
          <input
            class="form-control"
            type="text"
            name="original_order_id_number"
            placeholder="Original Order Number"
            v-model="formData.original_order_id_number"
            required
          />
          <!-- <ul v-if="legends.lastOrder" class="legend">
            <li><b>Last manual orders numbers</b></li>
            <li
              v-for="(id, type) in legends.lastOrders"
              :key="type"
              @click="
                (formData.id_number = id._id), (legends.lastOrder = false)
              "
            >
              {{ type }} → {{ id._id }}
            </li>
          </ul> -->
          <label for="date">Date</label>
          <input
            class="form-control"
            type="datetime-local"
            name="date"
            v-model="formData.date"
          />

          <label for="channel_group">Channel Group</label>
          <select class="form-control" name="channel_group" v-model="formData.channel_group">
            <option v-for="chg in channel_groups" :key="chg" :value="chg">{{ chg }}</option>
          </select>
          <label for="channel">Channel</label>
          <select class="form-control" name="channel" v-model="formData.channel">
            <option v-for="ch in channels" :key="ch" :value="ch">{{ ch }}</option>
          </select>

          <label for="source">Source</label>
          <select class="form-control" name="source" v-model="formData.source">
            <option v-for="s in sources" :key="s" :value="s">{{ s }}</option>
          </select>
          
          <label for="typology">Typology</label>
          <select class="form-control" name="typology" v-model="formData.typology" @change="typologyUpdated">
            <option v-for="t in typologies" :key="t.code" :value="t.code">{{ t.name }} | {{ t.description }} - ({{ t.code }})</option>
          </select>

          <label for="notes">Notes</label>
          <textarea
            class="form-control"
            type="text"
            name="notes"
            placeholder="Notes"
            rows="5"
            v-model="formData.notes"
          ></textarea>
        </div>
        <div class="col-12 col-md-6">
          <h5>Additional Details</h5>
          <label for="problem">Problem</label>
          <select class="form-control" name="problem" v-model="formData.problem">
            <option>Aesthetic</option>
            <option>Functional</option>
            <option>Incomplete</option>
            <option>Logistic</option>
          </select>
          <label for="commercial_partner_id">Commercial Partner Order Number</label>
          <br>
          <input
            class="form-control"
            type="text"
            name="commercial_partner_id"
            placeholder="Commercial Partner Order Number"
            v-model="formData.commercial_partner_id"
            required
          />
          <label for="internal_notes">Internal Notes</label>
          <textarea
            class="form-control"
            type="text"
            name="internal_notes"
            placeholder="Internal Notes"
            rows="7"
            v-model="formData.internal_notes"
          ></textarea>
          <div class="row">
            <div class="col-12">
              <label for="goods_readiness">Goods Readiness</label>
            </div>
            <div class="col-12 col-md-6">
              <select class="form-control" name="problem" v-model="selectedGoodsReadiness" @change="formData.goods_readiness = selectedGoodsReadiness">
                <option :value="false" disabled>Dates from Quote</option>
                <option v-for="(d,index) in formData.goods_readiness_dates" :value="d" :key="'gd'+index">{{ d | formatDate }}</option>
              </select>
            </div>
            <div class="col-12 col-md-6">
              <input
                class="form-control"
                type="date"
                name="date"
                v-model="formData.goods_readiness"
              />
            </div>
          </div>
          <label for="createdBy">Created By</label>
          <input class="form-control" name="createdBy" type="text" disabled v-model="formData.createdBy"/>
        </div>
      </div>
      <hr />
        <template v-if="formData.crm">
          <h5 class="salesforce">CRM</h5>
          <div class="row">
            <div class="col">
              <label class="salesforce">Order ID</label>
              <input class="form-control" type="text" v-model="formData.crm.orderId"/>
            </div>
            <div class="col">
          <label class="salesforce">Opportunity ID</label>
          <input class="form-control" type="text" v-model="formData.crm.opportunityId"/>
            </div>
            <div class="col">
          <label class="salesforce">Quote ID</label>
          <input class="form-control" type="text" v-model="formData.crm.quoteId"/>
            </div>
            <div class="col">
          <label class="salesforce">Account ID</label>
          <input class="form-control" type="text" v-model="formData.crm.accountId"/>
            </div>
            <div class="col">
          <label class="salesforce">Contact ID</label>
          <input class="form-control" type="text" v-model="formData.crm.contactId"/>
            </div>
            <div class="col">
          <label class="salesforce">Devices ID</label>
          <template v-for="(d, index) in formData.crm.devicesId">
            <input :key="d+index" class="form-control" type="text" v-model="formData.crm.devicesId[index]" />
          </template>
            </div>
            <div class="col">
              <div class="form-control btn btn-danger" @click="deleteCRM()">
                <i class="fa fa-times"></i>
              </div>
            </div>
        </div>
      </template>
      <hr />
      <div class="form-row align-items-start customers">
        <div class="col-12">
          <label for="customer">Search customer in Gateway</label>
          <input
            class="form-control form-control-md"
            type="text"
            name="customer"
            placeholder="Insert a keyword (name, email, company or vat number)"
            v-model="customerKeyword"
            @input.prevent="searchCustomers"
            @keyup.esc="resetCustomers"
          />
        </div>
        <div class="col-6">
          <label class="salesforce" for="accountId">Salesforce Account ID</label>
          <input name="accountId" v-model="salesforceAccountId" class="form-control" type="text" placeholder="Insert Salesforce Account ID | Billing Address" @keyup.enter="salesforceGetAccount()" />
        </div>
        <div class="col-6">
          <label class="salesforce" for="contactId">Salesforce Contact ID</label>
          <input name="contactId" v-model="salesforceContactId" class="form-control" type="text" placeholder="Insert Salesforce Contact ID | Shipping Address" @keyup.enter="salesforceGetContact()" />
        </div>
        <div class="col-12">
          <div
            class="row customers__customer"
            v-for="(customer, index) in customers"
            :key="index"
            @click.prevent="setCustomer(customer)"
          >
            <div class="col-6">
              <strong v-text="customer.billing.name"></strong>
              <span v-text="customer.billing.company"></span>
              <span v-text="customer.billing.vat_number"></span>
              <span v-text="customer.billing.address_1"></span>
              <span v-text="customer.billing.address_2"></span>
              <span v-text="customer.billing.city"></span>
              <span v-text="customer.billing.state"></span>
              <span v-text="customer.billing.postcode"></span>
              <strong v-text="customer.billing.country"></strong>
              <span v-text="customer.billing.email"></span>
              <span v-text="customer.billing.phone"></span>
              <span v-text="customer.billing.e_invoice"></span>
            </div>
            <div class="col-6">
              <strong v-text="customer.shipping.name"></strong>
              <span v-text="customer.shipping.company"></span>
              <span v-text="customer.shipping.vat_number"></span>
              <span v-text="customer.shipping.address_1"></span>
              <span v-text="customer.shipping.address_2"></span>
              <span v-text="customer.shipping.city"></span>
              <span v-text="customer.shipping.state"></span>
              <span v-text="customer.shipping.postcode"></span>
              <strong v-text="customer.shipping.country"></strong>
              <span v-text="customer.shipping.email"></span>
              <span v-text="customer.shipping.phone"></span>
              <span v-text="customer.shipping.e_invoice"></span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div id="address" class="form-row align-items-start">
        <div class="col-12 row">
          <div class="col-12 mb-2">
            <h5>Billing Info</h5>
          </div>
          <div class="col-12 col-md-6">
            <label for="name">First Name</label>
            <input
              class="form-control"
              type="text"
              name="billing_billing_first_name"
              placeholder="First Name"
              v-model="formData.billing.first_name"
              required minlength="1"
            />
          </div>
          <div class="col-12 col-md-6">
            <label for="name">Last Name</label>
            <input
              class="form-control"
              type="text"
              name="billing_billing_last_name"
              placeholder="Last Name"
              v-model="formData.billing.last_name"
              required minlength="1"
            />
          </div>
          <div class="col-12 col-md-6">
            <label for="company">Company</label>
            <input
              class="form-control"
              type="text"
              name="billing_company"
              placeholder="Company"
              v-model="formData.billing.company"
            />
          </div>
          <div class="col-12 col-md-6">
            <label for="vat_number">VAT Number/P.IVA</label>
            <input
              class="form-control"
              type="text"
              name="billing_vat_number"
              placeholder="VAT Number"
              v-model="formData.billing.vat_number"
              @change="updateVAT()"
            />
          </div>
          <div class="col-12 col-md-6 mb-4 offset-md-6">
            <label for="einvoice">Electronic Invoice</label>
            <input
              class="form-control"
              type="text"
              name="billing_einvoice"
              placeholder="E-Invoice Number"
              v-model="formData.billing.e_invoice"
            />
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 row">
          <div class="col-12 mb-2">
            <h5>Billing Address</h5>
          </div>
          <div class="col-12 col-md-6">
            <label for="address_1">Address 1</label>
            <input
              id="billing"
              ref="autocomplete"
              class="form-control"
              type="text"
              name="billing_address_1"
              placeholder="Address 1"
              v-model="formData.billing.address_1"
            />
          </div>
          <div class="col-12 col-md-6">
            <label for="address_2">Address 2</label>
            <input
              class="form-control"
              type="text"
              name="billing_address_2"
              placeholder="Address 1"
              v-model="formData.billing.address_2"
            />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="postcode">Postcode</label>
            <input
              class="form-control"
              type="text"
              name="billing_postcode"
              placeholder="Postcode"
              v-model="formData.billing.postcode"
            />
          </div>
          <div class="col-12 col-md-6">
            <label for="city">City</label>
            <input
              class="form-control"
              type="text"
              name="billing_city"
              placeholder="City"
              v-model="formData.billing.city"
            />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="country">Province/State</label>
            <input
              class="form-control"
              type="text"
              name="billing_country"
              placeholder="Province/State"
              v-model="formData.billing.state"
            />
          </div>
          <div class="col-sm-12 col-md-12">
            <label for="country">Country</label>
            <select
              v-model="formData.billing.country"
              class="form-control"
              name="billing_country"
              @change="updateVAT()"
            >
              <option disabled selected value="">Select a country</option>
              <option
                v-for="c in countries"
                :key="c.code"
                :value="c.code"
                >{{ c.name }}</option
              >
            </select>
          </div>
          <div class="col-12 col-md-6">
            <label for="email">Email</label>
            <input
              class="form-control"
              type="email"
              name="billing_email"
              placeholder="Email"
              v-model="formData.billing.email"
              required minlength="4"
            />
          </div>
          <div class="col-12 col-md-6">
            <label for="phone">Phone</label>
            <input
              class="form-control"
              type="text"
              name="billing_phone"
              placeholder="Phone"
              v-model="formData.billing.phone"
            />
          </div>
          <div class="col-12">
            <label for="tax_rate"
              >VAT % <strong>{{ formData.shipping.country }}</strong>
              <span v-if="formData.billing.vat_number && formData.billing.vat_number.length">
                - B2B <strong>{{ formData.billing.vat_number }}</strong></span
              ></label
            >
            <input
              class="form-control"
              name="tax_rate"
              type="text"
              v-model="formData.tax_rate"
              @change="updateAllProducts()"
            />
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 row">
          <div class="col-auto mb-2">
            <h5>Shipping Address</h5>
          </div>
          <div class="col-auto">
            <div class="mt-1">
              <input
                class="mr-2"
                type="checkbox"
                id="shippingasbilling"
                @change="copyBilling()"
                v-model="shipping_as_billing"
              />
              <label for="shippingasbilling">Ship to a different address</label>
            </div>
          </div>
          <div class="col-auto" v-if="formData.crm_rules">
            <div class="mt-1">
              <input
                class="mr-2"
                type="checkbox"
                id="crm_shipping_update"
                v-model="formData.crm_rules.shipping_address_update"
              />
              <label for="crm_shipping_update">Update Account/Contact shipping address in Salesforce</label>
            </div>
          </div>
          <div id="shipping_address" class="col-12 row shipping">
            <div class="col-12 col-md-6">
              <label for="name">First Name</label>
              <input
                class="form-control shipping_address_field"
                type="text"
                name="shipping_shipping_first_name"
                placeholder="First Name"
                v-model="formData.shipping.first_name"
                required minlength="1"
                :disabled="shipping_as_billing"
              />
            </div>
            <div class="col-12 col-md-6">
              <label for="name">Last Name</label>
              <input
                class="form-control shipping_address_field"
                type="text"
                name="shipping_shipping_last_name"
                placeholder="Last Name"
                v-model="formData.shipping.last_name"
                required minlength="1"
                :disabled="shipping_as_billing"
              />
            </div>
            <div class="col-12 col-md-6">
              <label for="address_1">Address 1</label>
              <input
                id="shipping"
                ref="autocomplete"
                class="form-control shipping_address_field"
                type="text"
                name="shipping_address_1"
                placeholder="Address 1"
                v-model="formData.shipping.address_1"
                :disabled="shipping_as_billing"
              />
            </div>
            <div class="col-12 col-md-6">
              <label for="address_2">Address 2</label>
              <input
                class="form-control shipping_address_field"
                type="text"
                name="shipping_address_2"
                placeholder="Address 1"
                v-model="formData.shipping.address_2"
                :disabled="shipping_as_billing"
              />
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="postcode">Postcode</label>
              <input
                class="form-control shipping_address_field"
                type="text"
                name="shipping_postcode"
                placeholder="Postcode"
                v-model="formData.shipping.postcode"
                :disabled="shipping_as_billing"
              />
            </div>
            <div class="col-12 col-md-6">
              <label for="city">City</label>
              <input
                class="form-control shipping_address_field"
                type="text"
                name="shipping_city"
                placeholder="City"
                v-model="formData.shipping.city"
                :disabled="shipping_as_billing"
              />
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="country">Province/State</label>
              <input
                class="form-control shipping_address_field"
                type="text"
                name="shipping_country"
                placeholder="Province/State"
                v-model="formData.shipping.state"
                :disabled="shipping_as_billing"
              />
            </div>
            <div class="col-sm-12 col-md-12">
              <label for="country">Country</label>
              <select
                v-model="formData.shipping.country"
                class="form-control shipping_address_field"
                name="shipping_country"
                @change="updateVAT()"
                :disabled="shipping_as_billing"
              >
                <option disabled selected value="">Select a country</option>
                <option
                  v-for="c in countries"
                  :key="c.code"
                  :value="c.code"
                  >{{ c.name }}</option
                >
              </select>
            </div>
            <div class="col-12 col-md-6">
              <label for="email">Email</label>
              <input
                class="form-control shipping_address_field"
                type="email"
                name="shipping_email"
                placeholder="Email"
                v-model="formData.shipping.email"
                minlength="4"
                required
                :disabled="shipping_as_billing"
              />
            </div>
            <div class="col-12 col-md-6">
              <label for="phone">Phone</label>
              <input
                class="form-control shipping_address_field"
                type="text"
                name="shipping_phone"
                placeholder="Phone"
                v-model="formData.shipping.phone"
                :disabled="shipping_as_billing"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="form-row">
        <div class="col-12 mb-4">
          <h5>Products</h5>
          <label>SAP PriceList ID</label>
          <select class="form-control" style="width: 200px" v-model="formData.sap_pricelist_id" @change="setPriceListCurrency">
            <option :value="false">Select Price List</option>
            <option :value="5">5 - EUR (UE)</option>
            <option :value="6">6 - EUR (Extra UE)</option>
            <option :value="7">7 - GBP (GB)</option>
            <option :value="8">8 - USD (CA/US)</option>
          </select>
        </div>
        <div class="col-12 col-lg-12">
          <div class="row align-items-center">
            <div class="col-12 col-md-5">
              <h6><strong>Prodotti finiti</strong></h6>
              <select
                class="form-control"
                id="products"
                v-model="selectedProduct"
                @click="getProducts()"
              >
                <option selected="selected" value="" disabled>{{ this.isLoading ? 'Loading SAP products...' : 'Select a product' }}</option>
                <optgroup
                  v-for="(value, key) in products_list"
                  :key="key"
                  :label="key"
                >
                  <option
                    v-for="product in value"
                    :key="product.itemCode"
                    :value="product"
                    v-text="product.itemCode + ' | ' + product.itemName"
                  ></option>
                </optgroup>
              </select>
            <!-- </div>
            <div class="col-12 col-md-5"> -->
              <h6><strong>Ricambi</strong></h6>
              <select
                class="form-control"
                id="products"
                v-model="selectedProduct"
                @click="getProducts()"
              >
                <option selected="selected" value="" disabled>{{ this.isLoading ? 'Loading SAP products...' : 'Select a product' }}</option>
                <optgroup
                  v-for="(value, key) in replacements_list"
                  :key="key"
                  :label="key"
                >
                  <option
                    v-for="product in value"
                    :key="product.itemCode"
                    :value="product"
                    v-text="product.itemCode + ' | ' + product.itemName"
                  ></option>
                </optgroup>
              </select>
              <h6><strong>Packaging</strong></h6>
              <select
                class="form-control"
                id="products"
                v-model="selectedProduct"
                @click="getProducts()"
              >
                <option selected="selected" value="" disabled>{{ this.isLoading ? 'Loading SAP products...' : 'Select a product' }}</option>
                <optgroup
                  v-for="(value, key) in packaging_list"
                  :key="key"
                  :label="key"
                >
                  <option
                    v-for="product in value"
                    :key="product.itemCode"
                    :value="product"
                    v-text="product.itemCode + ' | ' + product.itemName"
                  ></option>
                </optgroup>
              </select>
              <template>
                <h6><strong>Services & Marketing</strong></h6>
                <select
                  class="form-control"
                  id="products"
                  v-model="selectedProduct"
                  @click="getProducts()"
                >
                  <option selected="selected" value="" disabled>{{ this.isLoading ? 'Loading SAP products...' : 'Select a product' }}</option>
                  <optgroup
                    v-for="(value, key) in services_list"
                    :key="key"
                    :label="key"
                  >
                    <option
                      v-for="product in value"
                      :key="product.itemCode"
                      :value="product"
                      v-text="product.itemCode + ' | ' + product.itemName"
                    ></option>
                  </optgroup>
                </select>
              </template>
            </div>
            <div class="col-12 col-md-2">
              <button
                class="btn btn-primary btn-block"
                @click.prevent="addProduct(selectedProduct)"
                :disabled="!this.formData.sap_pricelist_id"
              >
                Add to order
              </button>
            </div>
            <div class="col-12 col-md-4"></div>
          </div>
        </div>
        <div class="col-12 col-lg-6"></div>
        <div class="col-12" v-if="formData.products.length > 0">
          <table class="products-table table-sm">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price (net)</th>
                <th>Subtotal</th>
                <th>Subtotal Tax</th>
                <th>Discount %</th>
                <th>Total</th>
                <th>Total Tax</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in formData.products" :key="product.sku">
                <td>
                  <kbd>{{ product.sku }}</kbd>
                </td>
                <td>
                  {{ product.name }}
                </td>
                <td>
                  <input
                    class="form-control number"
                    name="quantity"
                    type="number"
                    min="1"
                    placeholder="Quantity"
                    v-model.number="product.quantity"
                    @input="updateProduct(product)"
                  />
                </td>
                <td>
                  <input
                    class="form-control number"
                    name="price"
                    type="number"
                    min="0"
                    placeholder="Price"
                    v-model.number="product.price"
                    step="any"
                    @input="updateProduct(product)"
                  />
                </td>
                <td>
                  <input
                    class="form-control number"
                    name="subtotal"
                    type="number"
                    placeholder="Subtotal"
                    v-model.number="product.subtotal"
                    step="any"
                  />
                </td>
                <td>
                  <input
                    class="form-control number"
                    name="subtotal_tax"
                    type="number"
                    placeholder="Subtotal Tax"
                    v-model.number="product.subtotal_tax"
                    step="any"
                  />
                </td>
                <td>
                  <input
                    class="form-control number"
                    name="discount_percentage"
                    type="number"
                    placeholder="%"
                    v-model.number="product.discount_percentage"
                    @input="updateProduct(product)"
                    step="any"
                  />
                </td>
                <td>
                  <input
                    class="form-control number"
                    name="total"
                    type="number"
                    placeholder="Total"
                    v-model.number="product.total"
                    step="any"
                  />
                </td>
                <td>
                  <input
                    class="form-control number"
                    name="total_tax"
                    type="number"
                    placeholder="Total Tax"
                    v-model.number="product.total_tax"
                    step="any"
                  />
                </td>
                <td>
                  <button
                    class="form-control btn btn-danger"
                    @click="removeProduct(product)"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <div
        v-if="formData.products.length > 0 && $router.user.roleScore > 1"
        class="form-row packages"
      >
        <div class="col-12">
          <h5>Packages</h5>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <select class="form-control" id="products" v-model="selectedPackage" @click="getPackaging()">
            <option selected="selected" value="" disabled>{{ this.isLoading ? 'Loading SAP packages...' : 'Select a package' }}</option>
            <option
              v-for="pack in packages_list"
              :key="pack.sku"
              :value="pack"
              v-text="pack.sku + ' | ' + pack.name"
            ></option>
          </select>
          <div v-if="selectedPackage">
            <div
              v-if="formData.products.length"
              class="products products--available"
            >
              <span>Click products to <b>add</b> to package:</span><br />
              <span
                v-for="product in formData.products"
                :key="product.sku"
                v-text="product.sku"
                @click="selectedPackage.products.push(product.sku)"
                class="product"
              ></span>
            </div>
            <div
              v-if="selectedPackage.products.length"
              class="products products"
            >
              <span>Click products to <b>remove</b> from package:</span><br />
              <span
                v-for="(sku, index) in selectedPackage.products"
                :key="sku"
                v-text="sku"
                @click="selectedPackage.products.splice(index, 1)"
                class="product"
              ></span>
            </div>
            <div class="d-inline-flex mt-2" v-if="selectedPackage.sku === 'ST-PKGAA00'">
              <div class="form-group mr-2">
                <label for="packageWidth">Width (cm)</label>
                <input class="form-control mr-3" size="6" type="number" name="packageWidth" v-model="selectedPackage.dimensions.width"/>
              </div>
              <div class="form-group mr-2">
                <label for="packageLength">Length (cm)</label>
                <input class="form-control mr-3" size="6" type="number" name="packageLength" v-model="selectedPackage.dimensions.length"/>
              </div>
              <div class="form-group mr-2">
                <label for="packageHeight">Height (cm)</label>
                <input class="form-control mr-3" size="6" type="number" name="packageHeight" v-model="selectedPackage.dimensions.height"/>
              </div>
              <div class="form-group mr-2">
                <label for="packageWeight">Weight (kg)</label>
                <input class="form-control mr-3" size="6" type="number" name="packageWeight" v-model="selectedPackage.dimensions.weight"/>
              </div>
            </div>
            <div class="d-inline-flex mt-1">
              <input class="form-control mr-3" size="3" type="number" name="packageQuantity" v-model="packageQuantity"/>
              <button
                :disabled="!selectedPackage.products.length > 0"
                class="btn btn-block ups--alt package__btn"
                @click.prevent="addPackage()"
              >
                <i class="fas fa-box-open"></i> Add Packages
              </button>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-6 col-lg-7"
          v-if="formData.packages.length > 0"
        >
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>SKU | Name</th>
                <th>Length</th>
                <th>Width</th>
                <th>Height</th>
                <th>Weight</th>
                <th>Products ({{ productsPackagedCount }}/{{ productsCount }} )</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(singlePackage, index) in formData.packages"
                :key="index"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ singlePackage.sku + " | " + singlePackage.name }}</td>
                <td>{{ singlePackage.dimensions.length }}</td>
                <td>{{ singlePackage.dimensions.width }}</td>
                <td>{{ singlePackage.dimensions.height }}</td>
                <td>{{ singlePackage.dimensions.weight }}</td>
                <td>{{ singlePackage.products.join(", ") }}</td>
                <td>
                  <a
                    @click="formData.packages.splice(index, 1), formData.shipments.splice(index, 1)"
                    class="fa fa-trash-alt"
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <div class="form-row" v-if="isSubscription">
        <div class="col-12">
          <h5>Subscription</h5>
        </div>
        <div class="col-12 col-md-3">
          <label for="start_date">Start Date</label>
          <input
            class="form-control"
            type="datetime-local"
            name="start_date"
            v-model="formData.subscription.start_date"
          />
        </div>
        <div class="col-12 col-md-3">
          <label for="end_datedate">End Date</label>
          <input
            class="form-control"
            type="datetime-local"
            name="end_date"
            v-model="formData.subscription.end_date"
          />
        </div>
        <div class="col-12 col-md-3">
          <label for="date">Payment Type</label>
          <select
            class="form-control"
            name="payment_terms"
            v-model="formData.subscription.payment_terms"
          >
            <option value="monthly">Mensile</option>
            <option value="quarterly">Trimestrale</option>
            <option value="yearly">Annuale</option>
          </select>
        </div>
      </div>
      <hr />
      <div class="form-row">
        <div class="col-12">
          <h5>Payment</h5>
        </div>
        <div class="col-12 col-md-3">
          <label for="currency">Currency</label>
          <select class="form-control" name="currency" v-model="formData.currency" :disabled="formData.sap_pricelist_id">
            <option v-for="cy in ['EUR','USD','GBP']" :key="cy" :value="cy">{{ cy }}</option>
          </select>
        </div>
        <div class="col-12 col-md-9"></div>
        <div class="col-12 col-md-3">
          <label for="shipping_total">Shipping Total</label>
          <input
            class="form-control"
            type="number"
            name="shipping_total"
            placeholder="Shipping Total"
            v-model="formData.shipping_total"
            @input="updateTotals()"
          />
          <label for="shipping_tax">Shipping Tax</label>
          <input
            class="form-control"
            type="number"
            name="shipping_tax"
            placeholder="Shipping Tax"
            v-model="formData.shipping_tax"
            @input="updateTotals()"
          />
        </div>
        <div class="col-12 col-md-3">
          <label for="discount_total">Discount Total</label>
          <input
            class="form-control"
            type="number"
            name="discount_total"
            placeholder="Discount Total"
            v-model="formData.discount_total"
          />
          <label for="discount_percentage">Discount Percentage</label>
          <input
            class="form-control"
            type="number"
            name="discount_percentage"
            placeholder="Discount Total"
            v-model="formData.discount_percentage"
          />
        </div>
        <div class="col-12 col-md-3">
          <label for="total"><strong>TOTAL</strong></label>
          <input
            class="form-control border border-secondary"
            type="number"
            name="total"
            placeholder="Total"
            v-model="formData.total"
          />
          <label for="total_tax"><strong>TOTAL TAX</strong></label>
          <input
            class="form-control border border-secondary"
            type="number"
            name="total_tax"
            placeholder="Total Tax"
            v-model="formData.total_tax"
          />
        </div>
        <div class="col-12 col-md-3"></div>
        <div class="col-12 col-md-3" v-if="formData.shipping_details">
          <label>Shipping Method</label>
          <div class="mt-2">
            <input
              id="shipping_standard"
              type="radio"
              class="mr-2"
              :value="false"
              v-model="formData.shipping_details.express"
            />
            <label for="shipping_standard">Standard</label>
            <input
              id="shipping_express"
              type="radio"
              class="ml-4 mr-2"
              :value="true"
              v-model="formData.shipping_details.express"
            />
            <label for="shipping_express">Express</label>
          </div>
          <input
            class="form-control"
            type="hidden"
            name="shipping_method"
            placeholder="Shipping Method"
            v-model="formData.shipping_method"/>
        </div>
        <div class="col-12 col-md-3">
          <label>Departure From:</label>
          <select class="form-control" name="departure_location" v-model="formData.departure_location" @change="formData.departure_location === 'HIVE' ? formData.warehouse = 'Hive' : ''">
            <option v-for="d in departure_locations" :key="d" :value="d">{{ d }}</option>
          </select>
        </div>
        <div class="col-12 col-md-3">
          <label>Warehouse:</label>
          <select class="form-control" name="warehouse" v-model="formData.warehouse">
            <option v-for="w in warehouses" :key="w.code" :value="w.code">{{ w.code }} - {{ w.name }}</option>
          </select>
        </div>
        <div class="col-12 col-md-3"></div>
        <div class="col-6 col-md-3">
          <label>Invoice or DDT</label>
          <div class="mt-2">
            <input
              type="radio"
              class="mr-2"
              id="invoice"
              :value="true"
              v-model="formData.invoice"
            />
            <label for="invoice">Invoice</label>
            <input
              type="radio"
              class="ml-4 mr-2"
              id="ddt"
              :value="false"
              v-model="formData.invoice"
            />
            <label for="ddt">DDT</label>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <label for="payment_method">Payment Method</label>
          <select class="form-control" name="payment_method" v-model="formData.payment.payment_method">
            <option v-for="pm in payment_methods" :key="pm.code" :value="pm.code">{{ pm.name }} | ({{ pm.code }})</option>
          </select>
        </div>
        <div class="col-12 col-md-6"></div>
        <div class="col-6 col-md-3">
          <label>Payment Status</label><br>
          <div class="mt-2">
            <input
              class="mr-2"
              type="radio"
              id="payment_paid"
              name="payment_paid"
              :value="true"
              v-model="formData.payment.paid"
            />
            <label for="payment_paid">Paid</label>
            <input
              class="ml-4 mr-2"
              type="radio"
              id="payment_not_paid"
              name="payment_paid"
              :value="false"
              v-model="formData.payment.paid"
            />
            <label for="payment_not_paid">Not Paid</label>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <label for="paid_percentage">Paid Percentage</label>
          <input
            class="form-control"
            type="number"
            name="paid_percentage"
            placeholder="Paid Percentage"
            v-model="formData.paid_percentage"
          />
        </div>
        <div class="col-12 col-md-6"></div>
        <!-- <div class="col-6 col-md-3">
          <label>Accredit Note</label>
          <div class="mt-2">
            <input
              type="radio"
              class="mr-2"
              id="accredit_note_yes"
              :value="true"
              v-model="formData.accredit_note"
            />
            <label for="accredit_note_yes">Yes</label>
            <input
              type="radio"
              class="ml-4 mr-2"
              id="accredit_note_no"
              :value="false"
              v-model="formData.accredit_note"
            />
            <label for="accredit_note_no">No</label>
          </div>
        </div> -->
      </div>
      <div>
        <hr />
        <div class="form-row">
          <button v-if="editOrderId"
            type="submit"
            class="btn btn-lg btn-info btn-block text-uppercase"
            @click.prevent="updateOrder"
          >
            Update order
          </button>
          <button v-else
            type="submit"
            class="btn btn-lg btn-primary btn-block text-uppercase"
            @click.prevent="addOrder"
          >
            Place order
          </button>
        </div>
      </div>
      <br />
      <div class="alert alert-success" v-show="alertSuccess" role="alert">
        <strong>Well done!</strong> New order placed.
      </div>
      <div class="alert alert-danger" v-show="alertError" role="alert">
        <strong>Ops!</strong> Something went wrong. <br>
        Make sure the order id number has not already been used.
      </div>
    </form>
  </div>
</template>
<script>
import OrdersService from "@/services/OrdersService";
import SapService from "@/services/SapService";
import data from "@/assets/data.json";
export default {
  name: "NewOrder",
  data() {
    return {
      isLoading: false,
      primaryOrder: this.$route.params.primaryOrder,
      editOrderId: this.$route.params.editOrderId,
      alertSuccess: false,
      alertError: false,
      selectedProduct: "",
      products_list: false,
      replacements_list: false,
      packaging_list: false,
      services_list: false,
      selectedPackage: "",
      packages_list: false,
      packageQuantity: 1,
      shipping_as_billing: true,
      salesforceQuoteId: '',
      salesforceAccountId: '',
      salesforceContactId: '',
      selectedGoodsReadiness: false,
      selectedSapPriceListId: 5,
      formData: {
        id_number: '',
        invoice_number: "",
        date: "",
        currency: "",
        total: 0.00,
        total_tax: 0.00,
        shipping_total: 0.00,
        shipping_tax: 0.00,
        discount_total: 0.00,
        tax_rate: 0,
        discount_percentage: 0.00,
        shipping_method: "",
        shipping_details: {
          express: false,
        },
        channel_group: "",
        channel: "",
        source: "Gateway",
        billing: {
          name: "",
          company: "",
          vat_number: "",
          address_1: "",
          address_2: "",
          city: "",
          postcode: "",
          country: "",
          email: "",
          phone: "",
        },
        shipping: {
          name: "",
          company: "",
          vat_number: "",
          address_1: "",
          address_2: "",
          city: "",
          postcode: "",
          country: "",
          email: "",
          phone: "",
        },
        products: [],
        packages: [],
        shipments: [],
        payment: {
          payment_method: "",
          paid: true
        },
        typology: "",
        notes: "",
        internal_notes: "",
        departure_location: 'TGT',
        warehouse: 'Mc',
        subscription: {
          start_date: "",
          end_date: "",
          payment_terms: ""
        },
        invoice: true,
        crm_rules: {
          shipping_address_update: true
        }
      },
      selectedTypology: false,
      vat: 0,
      channel_groups: data.channel_groups,
      channels: data.channels,
      typologies: data.typologies,
      sources: data.sources,
      departure_locations: data.departure_locations,
      warehouses: data.warehouses,
      countries: [],
      tax_rates: data.tax_rates,
      payment_methods: data.payment_methods,
      // legends: {
      //   lastOrder: false,
      //   lastOrders: "",
      // },
      customerKeyword: "",
      customers: [],
      google: "",
    };
  },
  computed: {
    defaultDate: function () {
      let now = new Date();
      let today =
        now.getFullYear() +
        "-" +
        ("0" + (now.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + now.getDate()).slice(-2) +
        "T" +
        ("0" + now.getHours()).slice(-2) +
        ":" +
        ("0" + now.getMinutes()).slice(-2);
      return today;
    },
    shippingCountry(){
      return this.formData.shipping.country
    },
    vatNumber(){
      return this.formData.billing.vat_number
    },
    productsCount(){
      return this.formData.products.filter(p => p.sku.substring(0,2) !== 'ST').reduce((a, b) => a + b.quantity, 0)
    },
    productsPackagedCount(){
      return this.formData.packages.reduce((a, b) => a + b.products.length, 0)
    },
    isSubscription() {
      return this.formData.channel === 'Subscription'
    }
  },
  watch:{
    shippingCountry(){
      this.getPriceList(this.shippingCountry, this.vatNumber)

      if (!this.vatNumber?.length || this.shippingCountry === 'IT') {
        this.formData.tax_rate = this.tax_rates[this.shippingCountry] * 100 || 0
      } else {
        this.formData.tax_rate = 0
      }
    },
    vatNumber(){
      if (this.shippingCountry) {
        this.getPriceList(this.shippingCountry, this.vatNumber)
      }
    }
  },
  mounted() {
    this.initializeOrder()
    this.copyBilling()
    // this.getLastOrders()
    this.listCountries()
    this.autocomplete("billing")
    this.autocomplete("shipping")
    this.formData.createdBy = this.$router.user.email
  },
  methods: {
    generateIdNumber(){
      this.formData.id_number = `VY${Date.now()}`
    },
    async getPriceList(countryCode, vatNumber) {
      if (!this.editOrderId) {
        const { data } = await SapService.getPriceList({countryCode, vatNumber})
        this.formData.currency = data.currency
        this.formData.sap_pricelist_id = data.priceListId
      }
    },
    setPriceListCurrency(){
      if (!this.editOrderId) {
        this.formData.sap_pricelist_id === 8 ? this.formData.currency = 'USD' : this.formData.sap_pricelist_id === 7 ? this.formData.currency = 'GBP' : this.formData.currency = 'EUR'
      }
    },
    async getProducts() {
      this.isLoading = true
      const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
      if (!this.products_list || !this.replacements_list || !this.packaging_list) {
        const response = await SapService.getProducts({itemGroup: 'Prodotti finiti'})
        const products = response.data.item
        // const response2 = await SapService.getProducts({itemGroup: 'Ricambi'})
        const response2 = await SapService.getProducts({property1: 'Y'})
        const response3 = await SapService.getProducts({itemGroup: 'Logistica', productRange: 'Packaging'})
        const response4 = await SapService.getProducts({itemGroup: 'Materie prime', productRange: 'Packaging'})
        const replacement_products = [...response2.data.item]
        const packaging_products = [...response3.data.item, ...response4.data.item]
        this.products_list = await groupByKey(products, 'commercialName')
        this.replacements_list = await groupByKey(replacement_products, 'commercialName')
        this.packaging_list = await groupByKey(packaging_products, 'itemGroup')
      }
      if (!this.services_list) {
        const response4 = await SapService.getProducts({productRange: 'Services'})
        const response5 = await SapService.getProducts({itemGroup: 'Marketing'})
        const services = [...response4.data.item, ...response5.data.item]
        this.services_list = await groupByKey(services, 'commercialName')
      }
      this.isLoading = false
    },
    async getPackaging() {
      this.isLoading = true
      if (!this.packages_list){
        const response = await SapService.getProducts({itemGroup: 'Logistica', productRange: 'Packaging'})
        const packages = response.data.item
        this.packages_list = []
        if (packages && packages.length) { 
          packages.map((p) => {
            this.packages_list.push({
              name: p.itemName,
              sku: p.itemCode,
              quantity: 1,
              dimensions: {
                length: parseFloat(p.salesLength),
                width: parseFloat(p.salesWidth),
                height: parseFloat(p.salesHeight),
                weight: parseFloat(p.salesWeight)
              },
              products: []
            })
          })
        }
      }
      this.isLoading = false
    },
    // Products
    async addProduct(selectedProduct) {
      const { data } = await SapService.getProductPrice({sku: selectedProduct.itemCode, priceListId: this.formData.sap_pricelist_id})
      let orderProduct = {
        quantity: 1,
        sku: selectedProduct.itemCode || selectedProduct.sku,
        sku_code: selectedProduct.itemCode ? selectedProduct.itemCode.indexOf("_") > 0 ? selectedProduct.itemCode.substring(0, selectedProduct.itemCode.indexOf("_")) : selectedProduct.itemCode : selectedProduct.sku,
        sku_plug: selectedProduct.plug,
        discount_percentage: 0.00,
        discount_total: 0.00,
        price: data || 0,
        name: selectedProduct.itemName || selectedProduct.name
      }
      console.log(orderProduct)
      this.formData.products.push(orderProduct)
      this.updateProduct(this.formData.products[this.formData.products.length - 1])
      this.selectedProduct = ""
    },
    updateProduct(product) {
      let vat = this.formData.tax_rate / 100 + 1;
      let subtotal = Number(product.price) * Number(product.quantity);
      product.subtotal = subtotal.toFixed(2);
      let subtotalTax = (
        Math.round((subtotal * vat - subtotal) * 100) / 100
      ).toFixed(2);
      product.subtotal_tax = subtotalTax;
      product.total = (
        product.subtotal -
        product.subtotal * Number(product.discount_percentage / 100)
      ).toFixed(2);
      product.total_tax = (
        product.subtotal_tax -
        product.subtotal_tax * Number(product.discount_percentage / 100)
      ).toFixed(2);
      product.discount_total =
        Number(product.subtotal) +
        Number(product.subtotal_tax) -
        Number(product.total) -
        Number(product.total_tax);
      this.updateTotals();
    },
    removeProduct(selectedProduct) {
      this.formData.products.splice(
        this.formData.products.findIndex(
          (obj) => obj.sku === selectedProduct.sku
        ),
        1
      );
      this.updateTotals();
    },
    updateAllProducts() {
      if (!this.salesforceQuoteId) {
        this.formData.products.map((product) => {
          this.updateProduct(product);
        });
      }
    },
    updateVAT() {
      this.formData.tax_rate = this.countries.find(c => c.code === this.formData.shipping.country)?.vat || 0
      this.formData.billing.vat_number?.length &&
      this.formData.shipping.country !== "IT"
        ? (this.formData.tax_rate = 0)
        : "";
      this.formData.billing.vat_number?.length &&
      this.formData.shipping.country === "IT" &&
      (!this.formData.billing.e_invoice ||
        !this.formData.billing.e_invoice.length)
        ? (this.formData.billing.e_invoice = "XXXXXXX")
        : "";
      this.updateAllProducts();
    },
    typologyUpdated() {
      if (this.primaryOrder && this.primaryOrder.id_number) {
        const { id_code } = this.typologies.find(t => t.code === this.formData.typology)
        id_code ? (this.formData.id_number = `${this.primaryOrder.id_number}_${id_code}`) : (this.formData.id_number = "");
      }
      this.formData.invoice = true;
      if (this.formData.typology === 'sale_dropshipping') {
        this.formData.crm_rules.shipping_address_update = false
        this.formData.invoice = false
      } else {
        this.formData.crm_rules.shipping_address_update = true
      }

      ["sale", "free-sample"].includes(this.formData.typology)
        ? (this.formData.invoice = true)
        : (this.formData.invoice = false);
    },
    // Packages
    addPackage() {
      const pack = JSON.parse(JSON.stringify(this.selectedPackage))
      for (let i = 0; i < this.packageQuantity; i++) {
        this.formData.packages.push(pack)
        this.formData.shipments.push({ package: pack, products: pack.products })
      }
      this.selectedPackage = "";
    },
    // Order
    updateTotals() {
      this.formData.total = (
        Math.round(
          (this.formData.products.reduce(function (x, y) {
            return Number(x) + (Number(y["total"]) + Number(y["total_tax"]));
          }, 0) +
            Number(this.formData.shipping_total)) *
            100
        ) / 100
      ).toFixed(2);
      this.formData.total_tax = (
        Math.round(
          (this.formData.products.reduce(function (x, y) {
            return Number(x) + Number(y["total_tax"]);
          }, 0) +
            Number(this.formData.shipping_tax)) *
            100
        ) / 100
      ).toFixed(2);
      this.formData.discount_total = (
        Math.round(
          this.formData.products.reduce(function (x, y) {
            return Number(x) + Number(y["discount_total"]);
          }, 0) * 100
        ) / 100
      ).toFixed(2);
      this.formData.discount_percentage = (
        Math.round(
          (this.formData.products.reduce(function (x, y) {
            return Number(x) + Number(y["discount_percentage"]);
          }, 0) /
            this.formData.products.length) *
            100
        ) / 100
      ).toFixed(2);
    },
    copyBilling() {
      if (this.shipping_as_billing) {
        this.formData.shipping = this.formData.billing;
      } else {
        this.formData.shipping = {};
      }
    },
    async initializeOrder() {
      if (this.editOrderId) {
        const response = await OrdersService.getOrder(this.editOrderId)
        this.formData = { ...this.formData, ...response.data }
        delete this.formData["_id"]

        let d = new Date(this.formData.date)
        this.formData.date =d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "T" + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
        return
      } else if (this.primaryOrder) {
        const primaryOrder = JSON.parse(JSON.stringify(this.primaryOrder))
        this.formData = { ...this.formData, ...primaryOrder }
        this.formData.original_order_id_number = this.formData._id
        this.formData.original_order_sap_id = this.formData.sap_id
        this.formData.payment = {}
        delete this.formData["_id"];
        delete this.formData["status"];
        delete this.formData["shipping_status"];
        delete this.formData["shipping_label"];
        delete this.formData["shipping_paperless"];
        delete this.formData["sap_id"];
        delete this.formData["shipping_details"];
        delete this.formData["packages"];
        delete this.formData["shipments"];
        delete this.formData["createdAt"];
        delete this.formData["updatedAt"];
        delete this.formData["date"];
        delete this.formData["crm"];
        delete this.formData["logistic_partner_id"];
        delete this.formData["invoice_number"];
        delete this.formData["invoice_details"];
        delete this.formData["sap_documents"];
        this.formData.packages = [];
        this.formData.shipments = [];
        this.formData.shipping_details = {
          express: false,
        };
        !this.formData.billing.last_name ? this.formData.billing.last_name = this.formData.billing.name || this.formData.billing.company : ''
        !this.formData.shipping.last_name ? this.formData.shipping.last_name = this.formData.shipping.name || this.formData.shipping.company : ''
        !this.formData.billing.first_name ? this.formData.billing.first_name = "" : ''
        !this.formData.shipping.first_name ? this.formData.shipping.first_name = "" : ''
      } else {
        this.generateIdNumber()
      }
      
      this.formData.date = this.defaultDate;
    },
    async salesforceGetQuote(){
      this.shipping_as_billing = false
      const { data } = await OrdersService.getQuote(this.salesforceQuoteId)
      this.formData = {...this.formData, ...data}
      this.formData.date = this.defaultDate
      this.getPriceList(this.formData.shipping.country, this.formData.billing.vat_number)
      if (this.formData.goods_readiness_dates){
        this.formData.goods_readiness_dates = [...new Set(this.formData.goods_readiness_dates)]
        if (this.formData.goods_readiness_dates.length === 1){
          this.formData.goods_readiness = this.formData.goods_readiness_dates[0]
          this.selectedGoodsReadiness = this.formData.goods_readiness_dates[0]
        }
      }
      this.updateVAT()
      this.typologyUpdated()
    },
    async salesforceGetAccount(){
      const { data } = await OrdersService.getAccount(this.salesforceAccountId)
      this.formData.billing = data.billing
    },
    async salesforceGetContact(){
      const { data } = await OrdersService.getContact(this.salesforceContactId)
      this.formData.shipping = data.shipping
    },
    async addOrder() {
      this.formData.shipping_details.express ? this.formData.shipping_method = 'Express Shipping' : this.formData.shipping_method = 'Standard Shipping'
      if (this.formData.shipping.email && this.formData.shipping.email.length > 5) {
        this.formData.billing.name = this.formData.billing.first_name + ' ' + this.formData.billing.last_name
        this.formData.shipping.name = this.formData.shipping.first_name + ' ' + this.formData.shipping.last_name
        !this.formData.status && this.formData.payment.paid === false ? this.formData.status = 'waiting': ''
        !this.isSubscription ? delete this.formData["subscription"] : ''
        await OrdersService.addOrder([this.formData])
          .then((response) => {
            if (response.data !== "New Direct/Gateway orders imported 0 / 1") {
              this.alertError = false;
              this.alertSuccess = true;
              this.formData = {
                billing: {},
                shipping: {},
                products: [],
                payment: {},
                subscription: {}
              };
              setTimeout(() => {
                this.$router.push("/orders");
              }, 1000);
            } else {
              this.alertSuccess = false;
              this.alertError = true;
            }
          })
          .catch(() => {
            this.alertSuccess = false;
            this.alertError = true;
          });
      } else {
        alert('Indirizzo email in shipping obbligatorio!')
      } 
    },
    async updateOrder(){
      await OrdersService.updateOrder(this.formData.id_number, this.formData)
        .then((response) => {
          if (response) {
            this.alertError = false;
            this.alertSuccess = true;
            this.formData = {
              billing: {},
              shipping: {},
              products: [],
              payment: {},
            };
            setTimeout(() => {
              this.$router.push("/orders");
            }, 1000);
          } else {
            this.alertSuccess = false;
            this.alertError = true;
          }
        })
        .catch(() => {
          this.alertSuccess = false;
          this.alertError = true;
        });
    },
    deleteCRM(){
      this.formData.crm = false
      delete this.formData.crm
    },
    async searchCustomers() {
      let keyword = this.customerKeyword;
      if (keyword.length > 3) {
        this.loadingData = !this.loadingData;
        const response = await OrdersService.searchCustomers({ keyword });
        this.customers = response.data;
        this.loadingData = !this.loadingData;
      }
    },
    setCustomer(customer) {
      if (!customer.billing.last_name) {
        customer.billing = {...customer.billing, last_name: customer.billing.name || customer.billing.company}
      }
      if (!customer.shipping.last_name) {
        customer.shipping = {...customer.shipping, last_name: customer.shipping.name || customer.shipping.company}
      }
      this.formData.billing = customer.billing;
      this.formData.shipping = customer.shipping;
      this.resetCustomers();
      setTimeout(() => {
        window.scrollTo({
          top: document.getElementById("address").offsetTop,
          behavior: "smooth",
        });
      }, 100);
      this.updateVAT();
    },
    resetCustomers() {
      this.customers = [];
      this.customerKeyword = "";
    },
    // async getLastOrders() {
    //   const response = await OrdersService.getLastOrders();
    //   this.legends.lastOrders = response.data;
    // },
    async listCountries() {
      const countries = data.countries
      countries.map((c) => {
        this.countries.push({ name: c.name, code: c.alpha2Code, vat: c.vat });
      })
    },
    autocomplete(inputId) {
      var input = document.getElementById(inputId);
      const google = window.google;
      this.autocomplete[inputId] = new google.maps.places.Autocomplete(input);
      this.autocomplete[inputId].addListener("place_changed", () => {
        const { address_components } = this.autocomplete[inputId].getPlace();
        let address_number = "";
        address_components.forEach((a) => {
          switch (a.types[0]) {
            case "street_number":
              address_number = a.long_name;
              break;
            case "route":
              this.formData[inputId].address_1 = a.long_name;
              break;
            case "locality":
              this.formData[inputId].city = a.long_name;
              break;
            case "administrative_area_level_2":
              this.formData[inputId].state = a.short_name;
              break;
            case "country":
              this.formData[inputId].country = a.short_name;
              break;
            case "postal_code":
              this.formData[inputId].postcode = a.long_name;
              break;
          }
        });
        this.formData[inputId].address_1 =
          this.formData[inputId].address_1 + " " + address_number;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.form {
  position: relative;
  //background-color: #fff;
  width: 100%;
  padding: 4rem;
  @include tablet {
    padding: 2rem;
  }
  @include mobile {
    padding: 1rem;
  }
  margin: 0 auto;
  //box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
  &-row {
    justify-content: space-between;
  }
  h2,
  .checkbox {
    margin-bottom: 1rem;
  }
  .checkbox {
    font-weight: normal;
  }
  .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    //padding: .6rem 1rem;
    margin-bottom: 1rem;
    display: block;
    font-size: 0.9rem;
    @include placeholder {
      color: #d2d2d2;
      font-size: 0.85rem;
    }

    &:focus {
      z-index: 2;
    }
    &.number {
      width: 6.5rem;
    }
  }
  .input-group-btn {
    margin-left: 0.5rem;
  }
  .form-check {
    padding-left: 0;
    margin-bottom: 1rem;
    &-input {
      display: none;
      &:checked + .form-check-label {
        border-color: $green;
        color: $green;
      }
    }
    &-label {
      cursor: pointer;
      font-size: 0.8rem;
      padding: 0.6rem 1rem;
      min-width: 6rem;
      text-align: center;
      border: 1px solid;
      border-color: $red;
      color: $red;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }
  }

  .form-error .form-check {
    padding-left: 0;
    margin-bottom: 1rem;
    &-input {
      display: none;
      &:checked + .form-check-label {
        border-color: $green;
        color: $green;
      }
    }
    &-label {
      cursor: pointer;
      font-size: 0.8rem;
      padding: 0.6rem 1rem;
      min-width: 6rem;
      text-align: center;

      border: 1px solid;
      border-color: $red;
      color: $red;

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }
  }
  .back {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
  }
  .products-table {
    tr {
      //background-color: #fff !important;
      td,
      th {
        border: 0;
      }
      .form-control {
        margin-bottom: 0;
      }
    }
  }
  .inline {
    display: inline-flex;
  }
}

.packages {
  h5 {
    margin-bottom: 1.5rem;
  }
  label {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }
  padding: 2rem;
  background-color: #7b5d53;
  border-radius: 0.2rem;
  color: #fff;

  .products {
    border-bottom: 1px solid rgba(#fff, 0.1);
    padding: 1rem 0;
    .product {
      color: #000;
      padding: 0.2rem 0.5rem;
      display: inline-block;
      background-color: $ups;
      margin: 0.4rem 0.4rem 0 0;
      border-radius: 0.2rem;
      cursor: no-drop;
      font-size: 1rem;
    }
    &--available {
      margin-top: 1rem;
      border-top: 1px solid rgba(#fff, 0.1);
      .product {
        cursor: copy;
        background-color: rgba(#fff, 0.2);
        color: rgba(#fff, 0.4);
        border: 1px solid rgba(#fff, 0.2);
        &:hover {
          border: 1px solid $ups;
          color: $ups;
        }
      }
    }
  }
  table {
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
    thead tr {
      background-color: transparent !important;
      color: $ups;
    }
    td,
    th {
      border: 1px solid rgba(#fff, 0.5);
      padding: 0.5rem;
    }
    i {
      opacity: 0.25;
      margin-left: 0.75rem;
    }
  }
  button .fa-box-open {
    width: 1.5rem;
  }
  button:hover .fa-box-open:before {
    content: "\f466";
  }
}
.customers {
  border-left: 4px solid $clairy;
  padding-left: 1rem;
  &__customer {
    cursor: pointer;
    * {
      display: block;
    }
    padding: 0.5rem 0;
    border-bottom: 1px solid lightgrey;
    &:hover {
      background-color: rgba(lightgrey, 0.25);
    }
  }
}
.legend {
  padding: 1rem 1rem 1rem 2rem;
  margin-top: -1rem;
  background-color: rgba(0, 0, 0, 0.05);
  li {
    padding: 0.4rem;
    cursor: pointer;
    span {
      color: grey;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
h5 {
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.salesforce {
  font-weight: 700;
  color: $salesforce_color;
}
</style>
