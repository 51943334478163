<template lang="pug">
div
  h2 
    | Collaudo Dispositivi
    //- Ordine 
    //- router-link(:to="{ name: 'ProductionOrderDetails', params: { id_number } }") {{ id_number }}
  .row
    .col-12  
      span Shortcut: Double Press device button + Spacebar + Tab + Down Arrow + Enter
    .col-12.col-md-6
      SingleDeviceTesting(color="cyan")
    .col-12.col-md-6
      SingleDeviceTesting(color="purple")
    .col-12.col-md-6
      SingleDeviceTesting(color="orange")
    .col-12.col-md-6
      SingleDeviceTesting(color="yellow")
  div(style="margin-top: 2000px")
    p SMEG TEST
    .row
      .col-12.col-md-6
        SingleDeviceTesting2(color="cyan")
      .col-12.col-md-6
        SingleDeviceTesting2(color="purple")
      .col-12.col-md-6
        SingleDeviceTesting2(color="orange")
      .col-12.col-md-6
        SingleDeviceTesting2(color="yellow")
</template>
<script>
import SingleDeviceTesting from "./SingleDeviceTesting";
import SingleDeviceTesting2 from "./SingleDeviceTesting2";
export default {
  name: 'DeviceTesting',
  components: { SingleDeviceTesting, SingleDeviceTesting2 },
  computed: {
    id_number() { return this.$route.query?.id_number }
  },
}
</script>
